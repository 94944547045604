.payment-wrapper {
  min-height: 100vh;
  background-color: #20232e;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  gap: 20px;

  .payment-items {
    background-color: #fff;
    width: 80%;
    padding: 20px;
    min-height: 300px;
    border-radius: 8px;
  }
}
