/* The Modal (background) */
.message-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 70px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  //background-color: rgb(0,0,0); /* Fallback color */
  background-color: #e2e5e09e; /* Black w/ opacity */
}

/* Modal Content */
.message-modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.message-modal-close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.message-modal-close:hover,
.message-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.message-modal-header {
  padding: 2px 16px;
  background-color: #dc3545;
  color: white;
}

.message-modal-body {
  min-height: 200px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}
