.catalog-wrapper {
  width: 100%;
  .input-wrap {
    padding: 75px 20px;
    background-color: #515151;
    .ant-input-group-wrapper {
      .ant-input-wrapper {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .catalog-items-wrapper {
    display: flex;
    position: relative;
    gap: 20px;
    padding: 20px;
    min-height: 74vh;
    background-color: #20232e;

    .tabs {
      @media (max-width: 1100px) {
        margin-left: 0 !important;
      }
    }

    .filter {
      width: max-content;
      z-index: 100;
      left: 1%;
      transition: all 0.3s linear;

      @media (max-width: 1100px) {
        left: 2.4%;
      }
    }

    .all-items-wrapper {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .ant-tabs-tab {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    .ant-tabs-nav {
    }

    :where(.css-dev-only-do-not-override-18iikkb).ant-tabs-top >.ant-tabs-nav::before{
      border-bottom: none !important;
    }

    .ant-tabs-nav-wrap {
      &::before {
        display: none;
      }
    }

    .ant-tabs-tab-active {
      background-color: #008abd;
      padding: 5px;
      border-radius: 6px;
      text-decoration: none;

      .ant-tabs-tab-btn {
        color: #fff;
        text-decoration: none;
      }
    }

    @media (max-width: 1100px) {
      display: block;
    }
  }
}
