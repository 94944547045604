.JoL-multifunction .tooltip {
  visibility: visible;
  opacity: 1 !important;
}

.JoL-player-container {
  z-index: 1 !important;
  //width: 100% !important;
  margin: 0 !important;
}

.videoPlayerWrapper {
  width: 100%;
  aspect-ratio: 3/1.4;
  position: relative;

  .custom-player {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
  }
  video {
    object-fit: contain !important;
  }
}
