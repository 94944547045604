.filter-wrap {
  background-color: #303240;
  color: #fff;
  border-radius: 5px;
  min-width: 200px;

  .wrap {
    padding: 20px 30px;
  }

  // .filter-input {
  // 	.filter-input-wrap {
  // 		background-color: #fff;
  // 		padding: 7px 12px;
  // 		border-radius: 4px;

  // 	input {
  // 		background-color: #fff;
  // 		border: none;
  // 		outline: none;
  // 		width: 100%;
  // 	}
  // 	}
  // }
  .ant-rate-star-zero {
    svg {
    }
  }

  .show-result {
    margin-top: 15px;
    display: none;
    background-color: #474a59;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 10px 30px;

    p {
      margin-top: 15px;
    }

    .buttons {
      display: flex;
      gap: 20px;

      button {
        padding: 10px;
        transition: all 0.2s linear;

        &:active {
          transform: scale(0.98);
        }
      }

      .clear-btn {
        border: 2px solid #3383a9;
        background-color: transparent;
        color: #3383a9;
        font-weight: bold;
      }

      .show-btn {
        background-color: #67a302;
        border: none;
        color: #fff;
      }
    }
    @media (max-width: 1100px) {
      display: flex;
    }
  }

  @media (max-width: 1100px) {
    position: absolute;
    width: 95%;
    z-index: 100;
  }
}
