.client-wrapper {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #DFE0EB;



	.ant-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.copy-id {
	border: none;
	padding: 5px;
	border-radius: 5px;
	background-color: #ddd;
	transition: all .2s linear;


	&:hover {
		transform: scale(.98);
	}
}