.dashboard-chat-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  .course-top-wrap {
    padding: 15px;
  }

  .fa-eye {
    cursor: pointer;
    font-size: 20px;
    transition: all 0.2s linear;

    &:hover {
      color: #0c6dfd;
    }
  }

  .messages {
    // width: 100%;
    height: 70vh;
  }
}
.messages {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(145, 158, 171, 0.2);
    border-start-start-radius: 0px !important;
    border-end-start-radius: 0px !important;
    border-start-end-radius: 0px !important;
    border-end-end-radius: 0px !important;
  }

  thead.ant-table-thead > tr > th {
    background-color: rgba(145, 158, 171, 0.2);
    &::before {
      background-color: rgba(145, 158, 171, 0.5) !important;
    }
  }
}
