.admin-client-course-detail-wrapper {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #DFE0EB;

  .tree-course-section {
    margin: 0;
    border: 1px solid black;
    padding: 5px;
    display: inline-block;
  }

  .course-lesson-ul-wrapper {
    margin: 5px;
  }

  .course-lesson-wrapper {
    display: flex;
  }

  .course-lesson-block {
    display: block;
    border: 1px solid black;
    padding: 5px;
    font-size: 13px;
  }

  .course-lesson-block-bottom {
    font-size: 13px;
    border-top: 1px solid black
  }

  .admin-client-course-detail-table {
    th {
      width: 25%;
    }

    td, th {
      border: 1px solid;
      padding: 5px;
    }
  }

  .accd-course-tree {
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
  }

}
