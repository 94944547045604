.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //position: relative;
  padding: 0 40px;
  background-color: rgb(48, 50, 64);
  z-index: 10;

  .navbar-left {
    .navbar-links {
      display: flex;
      align-items: flex-start;
      margin: 0;
      .link {
        color: #fff;
        font-weight: bold;
        padding: 16px 10px;
      }

      .active-link {
        color: #fff;
        font-weight: bold;
        padding: 16px 10px;
        background-color: #1d1e26;
        border-bottom: 3px solid #097698;
      }
    }
    @media (max-width: 991px) {
      padding: 15px;
      .navbar-links {
        display: flex;
        gap: 40px;
        position: absolute;
        top: 65px;
        z-index: 100;
        width: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.621);
      }
    }
    @media (max-width: 576px) {
      .navbar-links__mobile {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #1d1e26;
        width: 100vw;
        height: 100vh;

        margin-top: 65px;
        z-index: 100 !important;
      }
    }
  }
  .navbar__link {
    color: #fff;
    transition: hover 0.5s ease;
    &:hover {
      text-decoration: underline;
    }
  }

  .navbar-right {
    position: relative;

    .avatar-name {
      color: #fff;
    }

    .user {
      cursor: pointer;
    }
  }

  .logo img {
    width: 50px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    padding: 0 10px;
  }
  .navbar-right_mobile {
    display: none;
  }
}
.burger-menu {
  color: #fff;
  display: none;
}
@media (max-width: 576px) {
  .navbar-wrapper {
    .navbar-right,
    .navbar-links {
      display: none !important;
    }
    .burger-menu {
      display: block;
    }
    .navbar-right_mobile {
      display: block !important;
    }
  }
}
