.verification-wrapper {
	height: 92.88vh;
	display: grid;
	place-items: center;
	color: #000;
	background-color: #20232e;

	.details-wrap {
		padding: 40px;
		border: 10px solid #eae7e7;
		margin: 0 auto;

		.verification-input {
			color: #000;

			.ReactInputVerificationCode__item {
				background-color: #e8f0fe;
			}
		}
		.confirm-btn {
			background-color: #039dcd;
			color: #fff;
			width: 100%;
			border: none;
			padding: 20px;
			font-size: 22px;
			border-radius: 50px;
			transition: all .2s linear;

			&:active {
				transform: scale(.98);
			}
		}
	}

	

	@media (max-width: 1100px) {
		height: 91.80vh;

		.details-wrap {
			padding: 15px;

			.verification-input {
				color: #000;
	
				.ReactInputVerificationCode__item {
					background-color: #e8f0fe;
					margin: 10px;
					width: 50px;
				}
			}
		}
	}
}