@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Francois+One&family=Signika+Negative:wght@600&display=swap');
.table-of-content-block-wrapper {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  //color: #474a59;
  //  font-family: Sans Regular,Arial,sans-serif;
  font-family: var(--bs-font-sans-serif);
  font-size: 16px;
  h1 {
    font-family: 'Francois One', sans-serif;
  }
}
