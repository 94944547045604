.course-lesson-wrapper {
  .course-wrapper-inputs {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #DFE0EB;
  }

  .course-lesson-editor {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #DFE0EB;
    margin-top: 20px;
  }

  .course-lesson-buttons {
    display: flex;
    padding: 20px;
  }
}

.course-lesson-editor {
  padding: 5px;
  //height: 500px;
  overflow: scroll;

  textarea {
    width: 100%;
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
  }
}

.rdw-editor-main {
  height: 400px !important;
  overflow: scroll;
}

.rdw-link-modal {
  padding: 15px;
  height: max-content;
}

.rdw-dropdown-selectedtext {
  span {
    color: #000;
  }
}

.simple-border {
  border: 1px solid black;
  border-radius: 5px;
}
