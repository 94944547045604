.table-wrapper {
  .table-of-content {
    color: #fff;

    svg {
      height: 13px;
      width: 13px;
    }

    .table-of-content-wrap {
      display: grid;
      align-items: flex-start;
      gap: 25px;
      grid-template-columns: 4fr 1.5fr;

      .block-wrapper {
        background-color: rgb(255, 255, 255);
        border-radius: 5px;
      }

      @media (max-width: 1100px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .course-section-list-tree {
    .title {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 5px;

      p {
        margin: 0;
      }

      svg {
        height: 10px;
        width: 10px;
      }
    }
    .child {
      padding: 0 !important;
      z-index: 100 !important;
    }
    .fa-ellipsis-vertical {
      margin-top: 5px;
      padding: 0 5px;
    }

    .ant-tree-node-content-wrapper {
      background-color: transparent !important;
    }
  }

  .ant-tree .ant-tree-switcher-leaf-line:after {
    width: 65%;
    z-index: -100;
  }

  .ant-tree-title {
    margin-bottom: 30px !important;
    padding: 0 0;
  }

  .ant-tree .ant-tree-treenode {
    padding: 0 !important;
  }

  .ant-tree .ant-tree-indent-unit {
    width: 0 !important;
  }

  .ant-modal {
    min-width: 600px !important;
  }
}

.ant-modal-content {
  .ant-modal-close {
    top: 5px;
    right: 10px;
    width: 25px;
    height: 25px;
    .ant-modal-close-x {
      line-height: 0;
    }
  }
}
.courses-tabs {
  .ant-tabs-content.ant-tabs-content-top {
    .header-tree {
      font-size: 16px;
    }
  }
}
