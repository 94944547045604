.register-component-wrapper {
  padding: 20px 30px;
  border-radius: 2px;
  width: 30%;
  color: #000;

  .text-center {
    h3 {
      font-size: 32px;
    }
  }
  .button-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    text-align: center;

    .button {
      font-size: 24px;
      padding: 10px;
      border: none;
      background-color: #039dcd;
      border-radius: 50px;
      transition: all 0.2s linear;

      &:active {
        transform: scale(0.98);
      }
    }

    .change-account {
      cursor: pointer;
      user-select: none;
    }
  }

  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 90%;
    padding: 20px 15px;
    .text-center {
      h3 {
        font-size: 28px;
      }
    }
    .registration-check-container {
      .ant-checkbox-wrapper > span:last-child {
        padding: 0;
        padding-left: 5px;
      }
      .registration-check-container-text {
        font-size: 16px;
      }
    }
    .button-wrap {
      .button {
        font-size: 20px;
      }
    }
  }
}

.registration-check-container {
  text-align: center;
  padding-bottom: 5px;
  input {
    padding-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  .registration-check-container-text {
    font-size: 18px;
    font-weight: 500;
  }
}
