.course-wrapper {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #dfe0eb;

  .course-top-wrap {
    width: 100%;
    flex-wrap: wrap;

    .inputs {
      flex-wrap: wrap;
    }
  }
}

.course-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  grid-auto-rows: 1fr;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 776px) {
    grid-template-columns: 1fr;
  }
}

.course-card-wrap {
  border-radius: 10px;
  color: #000;
  border: 1px solid #ddd;
  width: 100%;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;

  a {
    color: #000;
  }

  &:hover {
    box-shadow: 1px 3px 5px 5px #ddd;
  }

  .top {
    padding: 25px;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }

  .course-name {
    border-bottom: 1px solid #ddd;
  }

  .teachers-name {
    border-bottom: 1px solid #ddd;
  }
  .bottom-blocks {
    margin-bottom: 0;
    margin-top: auto;
  }
  .course-info {
    margin-bottom: 0.2rem;
    span {
      font-weight: 500;
    }
  }
}
