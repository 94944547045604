.loginPage {
  background-color: #20232e;
  height: 92.46vh;
  position: relative;

  @media (max-width: 1100px) {
    height: 91.8vh;
  }
}

.login-section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 576px) {
    position: relative;
    width: 90%;

    .login-button {
      border: none !important;
      padding: 10px !important;
      font-size: 19px !important;
    }
    .login-check-container-text {
      font-size: 16px;
    }
    .login-title {
      font-size: 24px;
    }
  }
}

.admin-search-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
