.card-wrapper {
	padding: 25px;
	display: flex;
	border: 3px solid #DFE0EB;
	width: 100%;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;
	cursor: pointer;
	background-color: #fff;
	transition: all .2s linear;

	p {
		font-weight: bold;
		color: #9FA2B4;
		transition: all .1s linear;
	}

	h1 {
		font-weight: bold;
		transition: all .1s linear;
	}


	&:hover {
		border-color: #3751FF;

		p,h1 {
			color: #3751FF;
		}
	}
}