.admin-client-detail-wrapper {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #DFE0EB;

  .profile-detail-wrapper {
    display: flex;
    gap: 10px;

    .admin-client-detail-img-wrapper {
      width: 25%;
    }

    .admin-client-detail-img {
      width: 100%;
    }
  }

  .client-assigned-course-wrapper {

  }

  .admin-client-detail-table {
    th {
      width: 35%;
    }

    td, th {
      border: 1px solid;
      padding: 5px;
    }
  }

}
