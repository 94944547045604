.video-wrapper {
  background-color: #20232e;
  height: 100%;
  position: relative;

  .navbar-wrapper {
    position: fixed;
    width: 100%;
  }

  .ant-upload-list-item-container {
    display: none;
  }

  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    background-color: #00a1ce;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
  }

  .ant-tabs-tabpane {
    //min-height: 400px;
    min-height: 85vh;
    //max-height: 80vh;
  }

  .chat-wrapper {
    width: 70vw !important;
    display: flex;
    margin: 0 auto;
    padding: 15px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 0;
    border-bottom: 0;
    flex-direction: column;
    justify-content: space-between;

    .chat-container {
      display: flex;
      flex-direction: column;
      padding: 15px;
      gap: 15px;
      height: 80vh;
      overflow-y: scroll;

      .message-img {
        position: relative;

        .message-img-icon {
          position: absolute;
          bottom: 5px;
          color: #fff;
          right: 5px;
        }
      }

      .left {
        text-align: start;

        span {
          padding: 5px;
          border-radius: 4px;
          background-color: #ddd;
        }
      }

      .right {
        text-align: end;

        span {
          padding: 5px;
          border-radius: 4px;
          background-color: #00a1ce;
          color: #fff;
        }
      }
    }

    .chat-input {
      display: flex;
      align-items: center;
      left: 26%;
      bottom: 0;
      padding: 10px;
      gap: 10px;
      width: 100%;

      input {
        width: 65vw;
        border: none;
        border-bottom: 1px solid #ddd;
        outline: none;
        padding: 8px 5px;
      }

      @media (max-width: 991px) {
        position: static;
        left: 5%;
      }
    }

    @media (max-width: 991px) {
      width: 90vw !important;
    }
  }

  .send-icon {
    border-radius: 5px;
    color: #fff;
    padding: 5px 14px;
    cursor: pointer;
    background-color: #00a1ce;
  }

  @media (max-width: 1100px) {
    .video-page-wrap {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 578px) {
    .video-page-wrap {
      flex-direction: column;
    }
  }
  @media (max-width: 991px) {
    .video-page-wrap {
      flex-direction: column;
    }
  }

  .video-player-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;

    .video-wrap {
      position: relative;
      width: 99%;
      margin: 0.5%;

      .chev-btn {
        position: absolute;
        top: 8%;
        left: 3%;
        z-index: 2;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 100%;
        background-color: #31323f;
        color: #fff;
        outline: none;
        border: none;
      }

      .video-section {
        position: relative;
        max-height: 86vh;
        > div {
          height: 85vh !important;
        }
        @media (max-height: 900px) {
          > div {
            height: 70vh !important;
          }
        }
        @media (max-height: 776px) {
          > div {
            height: 79vh !important;
          }
        }
        @media (max-width: 576px) {
          > div {
            height: 50vh !important;
          }
        }
      }
    }

    .course-introduction {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 95vh;

      button {
        margin-top: 50px;
        background-color: #00a1ce;
        width: max-content;
        padding: 10px 100px;
        border: none;
        transition: all 0.2s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 18px;

        &:active {
          transform: scale(0.98);
        }
      }
    }

    .video-description {
      background-color: #fff;
      padding: 10px 15px;
      min-height: 165px;

      .description {
        width: 100%;
        text-align: start;

        .course-material-controller {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          // gap: 50px;

          .ant-tabs-content {
            width: 70vw;
          }

          .tab-item {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
              height: 20px;
              width: 20px;
            }

            p {
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      button {
        background-color: #00a1ce;
        width: max-content;
        padding: 10px;
        border: none;
        transition: all 0.2s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &:active {
          transform: scale(0.98);
        }
      }
    }

    @media (max-width: 578px) {
      margin-left: 0;
      position: relative;
    }
    @media (max-width: 991px) {
      margin-left: 0;
      position: relative;
    }
  }

  .next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    width: 70vw;

    @media (max-width: 991px) {
      width: 90vw;
    }
  }
}

.app_player_wrapper {
  height: 85vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}
.vimo_player_wrapper {
  width: 100%;
  height: 88%;
  .vimo_iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // .vp-center {
    //   align-items: start !important;
    // }
  }
}

.video-player-wrapper__open {
  margin-left: 300px;
  transition: all 0.3s ease;
  margin-top: 55px;
}
