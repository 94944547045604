.course-detail-wrapper {
  --header-bg-color: linear-gradient(
      180deg,
      rgba(33, 35, 46, 0),
      rgba(62, 99, 205, 0.2) 0,
      rgba(33, 35, 46, 0)
    ),
    linear-gradient(360deg, rgba(33, 35, 46, 0.4), rgba(33, 35, 46, 0)),
    linear-gradient(92deg, #21232e, rgba(33, 33, 33, 0.8) 50%, #21232e);

  background-color: #20232e !important;
  font-family: var(--bs-font-sans-serif);

  .course-detail__hero {
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: var(--header-bg-color),
      url(https://cdn.esawebb.org/archives/images/screen/weic2216b.jpg);
  }
  .courses-tabs {
    margin-top: -175px;
    height: 100%;
    padding: 20px 20px 100px 20px;
    .ant-tabs-tab {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    .ant-tabs-nav:before {
      display: none;
    }

    .ant-tabs-nav-wrap {
      &::before {
        display: none;
      }
    }

    .ant-tabs-tab-active {
      background-color: rgb(0, 158, 204);
      padding: 5px;
      border-radius: 6px;
      text-decoration: none;

      .ant-tabs-tab-btn {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
.course-detail-wrapper .courses-tabs .ant-tabs-nav {
  .ant-tabs-tab {
    border: none;
    border-radius: 5px;
    line-height: 0;
    min-width: 100px;
    text-align: center;
    padding: 16px;
    background-color: #464958;
    .ant-tabs-tab-btn {
      width: 100%;
    }
  }
  .ant-tabs-tab-active {
    background-color: rgb(0, 158, 204);
    border: none;
  }
}
