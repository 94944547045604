.search-input {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 15px;
	padding: 12px 20px;
	border: 1px solid #989898;
	border-radius: 8px;
	background-color: #fff;


	input {
		width: 100%;
		border: none;
		outline: none;
	}
}


.search-btn {
 padding: 12px 20px;
 display: none;
 border-radius: 0 8px 8px 0;
 border: none;
 background-color: #009ecc;
 color: #fff;
 transition: all .2s linear;


 &:active {
	transform: scale(.98);
 }
}


@media (max-width: 1100px) {
	.search-input {
		border-radius: 8px 0 0 8px;
	}

	.search-btn {
		display: block;
	}
}