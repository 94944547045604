.client-wrapper {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #DFE0EB;



	.ant-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

