.catalog-item-wrapper {
  border-radius: 15px;
  transition: all 0.3s linear;
  cursor: pointer;
  margin: 0 auto;
  background-color: #303240;
  width: 350px;
  //  min-height: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover {
    transform: scale(0.98);
  }
  .description-tags {
    span {
      color: #009ecc;
      font-size: 14px;
      margin-right: 5px;
    }
  }
  .catalog-head {
    height: 120px;
    width: 100%;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    padding: 15px;

    .catalog-type {
      display: flex;
      align-items: center;
    }

    .head-icon {
      font-size: 20px;
    }
  }

  .catalog-item-mentor-about {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    padding: 20px;
    gap: 20px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .course-price {
    display: flex;
    padding: 0 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    color: #fff;
    margin-top: auto;
    p {
      margin-top: 10px;
    }

    i {
      margin-bottom: 7px;
    }
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
}
