.dashboard {
	background-color: #363740;
	transition: all .3s linear;
	z-index: 0;
	padding: 20px 0;
	min-height: 100vh;

	.icon {
		height: 45px;
		width: 45px;
	}


	.ant-menu {
		background-color: transparent;
		color: #9FA2B4;


		.ant-menu-item {
			width: 98%;
			padding: 20px 15px !important;
			border-radius: 0;
			&:hover{
				color: #fff !important;
			}

			.ant-menu-title-content {
				font-size: 15px;
			}
		}


		.ant-menu-item-active {
			color: #fff;
		}

		.ant-menu-item-selected {
			background-color: #62636d;
			border-left: 3px solid #fff;
			color: #fff;
		}
	}
}

.anticon {
	svg {
		height: 15px;
		width: 15px;
	}
}