.hero {
  height: 300px;
  background-image: url('../../assets/dark-mountain-09.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: #fff;
}
.student-active-courses {
  gap: 15px;
}
.see-all {
  padding: 2px 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
  a {
    color: inherit;
  }
}
