.summary-title {
	border-radius: 4px 4px 0 0;
}

.summary-block-title {
	padding: 10px;
	background-image: linear-gradient(270deg,rgba(80,198,208,.95),rgba(27,142,187,.95));
}
.summary-block-title-text{
	color: white;
}

.languages-wrapper {
	color: #000;
	padding: 20px 15px;
	display: flex;
	justify-content: space-around;



	.icon {
		font-size: 20px;
		margin-top: 10px;
	}
}

.study-btn-wrap {
	border-radius: 0 0 4px 4px;
	display: flex;
	justify-content: center;
	padding: 5px 0;
	border-top: 1px solid #ddd;
}

ul {
	margin: 0;
	padding: 0;
}


li {
	list-style: none;
	padding: 0;
	margin: 0;
}

