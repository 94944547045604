.client-wrapper {
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #DFE0EB;



	.ant-pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}


.ant-modal-mask {
	background-color: rgba(0, 0, 0, 0.106) !important;
}


.ant-pagination {
	display: flex !important;
	justify-content: center !important;
}