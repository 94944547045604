.course-section-wrapper {
  padding: 0px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dfe0eb;

  .course-section-top {
    border-bottom: 1px solid #ddd;
    padding: 5px;
  }

  .course-section-list-tree {
    position: relative;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      padding: 0px 3px;
      margin: 5px;
      margin-right: 10px;
      margin-bottom: 6px;
      z-index: 100;
      p {
        margin: 0;
      }
    }
    .child {
      padding: 0px 3px !important;
      margin-left: 30px !important;

      &::before {
        content: "";
        height: 9px;
        width: 9px;
        background-color: #000;
        color: #000;
        position: absolute;
        left: 20px;
        top: 9.5px;
        z-index: 10000;
        border-radius: 50%;
      }
    }

    .ant-tree-switcher-leaf-line {
      margin-top: 2px !important;
      z-index: 0;

      &::after {
        width: 40px !important;
        content: "";
      }
    }
    // .ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
    //   .ant-tree-title {
    //     div {
    //       background-color: #dc3545 !important;
    //       color: #fff !important;
    //     }
    //   }
    // }
    .fa-ellipsis-vertical {
      margin-top: 5px;
      padding: 0 5px;
    }

    .ant-tree-node-content-wrapper {
      background-color: transparent !important;
    }

    .circle-icon {
      color: #303240;
      background-color: #303240;
      border-radius: 50%;
      margin-top: 15px;
    }

    .anticon svg {
      height: 10px;
      z-index: 100;
      width: 10px;
    }
  }

  .course-section-part {
    height: 80vh;
    overflow: scroll;
  }
}

.update-btn {
  background-color: #ffc107;
  color: #000;

  &:hover {
    background-color: #ecb921 !important;
  }
}

.create-btn {
  background-color: #146c43;
  color: #fff;
}

.delete-btn {
  background-color: #dc3545;
  color: #fff;

  &:hover {
    background-color: #e44454 !important;
  }
}

.cancel-btn {
  background-color: #565e64;
  color: #fff;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #676f75;
  }
}
