.admin-login-wrap {
	background-color: #303240;
	height: 100vh;
	display: grid;
	place-items: center;

.admin-login-form {
	background-color: #fff;
	padding: 25px;
	min-width: 30vw;
	border-radius: 8px;
}
}
