.support-simple-chat-wrapper {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dfe0eb;

  input[type="file"] {
    display: none;
  }

  .support-simple-chat-header {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 0;

    .back-btn {
      button {
        border: none;
        background-color: #ddd;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }

  .support-simple-chat-container {
    width: 100% !important;
  }

  .support-simple-chat-inner-wrapper {
    display: flex;
  }

  .item__popover-content {
    margin-bottom: 0px !important;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }
  }

  .ant-tabs-nav {
    margin: 0;
  }
}
