.active-course {
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  padding-left: 20px;
  overflow: hidden;
  color: #21232e;
  text-align: start;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 10px;
    background-color: #8ab938;
  }
  p {
    margin-bottom: 8px;
  }
}
.active-course__bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(138, 185, 56, 0.2);
}
.active-course__name {
  font-weight: 600;
}
.active-course__progress {
  font-size: 14px;
}
