.about-us {
  box-sizing: border-box;
  background-color: #21232e;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
  }
  .main {
    flex: 1;
    //padding-top: 30px px;
  }
  .about-us-card {
    background-color: #303240;
    color: #fff;
    border-radius: 5px;
  }
  h5 {
    color: #fff;
    margin-bottom: 50px;
  }
  .footer {
  }
}
