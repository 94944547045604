.navbar-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5px;
	background-color: #fff;
	border: 1px solid #DFE0EB;
	border-radius: 8px;
	color: #000;
	font-size: 10px;

	.title {
		text-transform: capitalize;
		font-weight: bold;
		font-size: 16px;
		margin: 0px;
	}


	.user-data {
		display: flex;
		align-items: center;
		gap: 20px;
		.icons {
			display: flex;
			align-items: center;
			gap: 15px;
			padding: 0 10px;
			border-right: 2px solid #C5C7CD;

			.notification {
				position: relative;

				.notificate {
					position: absolute;
					height: 10px;
					width: 10px;
					border-radius: 50%;
					right: -1px;
					background-color: blue;
				}
			}

			i {
				color: #C5C7CD;
				font-size: 16px;
				cursor: pointer;
				transition: all .2s linear;


				&:active {
					color: #000;
				}
			}
		}

		.user-avatar {
			display: flex;
			align-items: center;
			gap: 15px;

			img {
				height: 40px;
				width: 40px;
				border-radius: 50%;
			}
		}
	}
}

.on-click-hand {
	cursor: pointer;
}