.public-page-wrapper {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: 80vh;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #DFE0EB;
	transition: all .2s linear;
	justify-content: center;
	align-items: center;


	.client-details {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}


	.client-image {
		height: 150px;
		width: 150px;
		border-radius: 50%;
	}
}