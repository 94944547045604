.simple-chat-main-wrapper {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dfe0eb;

  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    background-color: #00a1ce;
    color: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;

    &__icon {
      color: #fff;
    }
  }

  .simple-chat-wrapper {
    width: 100%;
    padding: 10px;
    overflow-y: scroll;
    height: 65vh;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(99, 115, 129, 0.48);
      border-radius: 10px;
    }

    .chat-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 50px;
      .left {
        //text-align: start;
        display: flex;
        align-self: start;

        span {
          padding: 5px 10px;
          border-radius: 4px;
          background-color: #ddd;
        }

        span.download-icon {
          background-color: darken($color: #ddd, $amount: 10%) !important;
        }
      }

      .right {
        display: flex;
        align-self: end;
        flex-direction: column;

        span {
          padding: 5px 10px;
          border-radius: 4px;
          background-color: #00a1ce;
          color: #fff;
        }
      }
    }

    .chat-input {
      bottom: 4%;
      display: flex;
      gap: 5px;
      padding: 4px;
      width: 70%;
      position: absolute;

      input {
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid #ddd;
        padding: 8px;
      }

      .paper {
        padding: 5px 20px;
        border-radius: 5px;
        color: #fff;
        background-color: #00a1ce;
        cursor: pointer;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }

      .send-icon {
        border-radius: 5px;
        color: #fff;
        padding: 5px 14px;
        cursor: pointer;
        background-color: #00a1ce;
      }
    }
  }

  .message-item {
    display: flex;
    align-items: center;
    gap: 10px;

    > i {
      font-size: 10px;
      margin-left: 15px;
      align-self: flex-end;
    }

    &__img {
      height: 150px;
      width: 200px;
      border-radius: 10px;
      cursor: pointer;
      object-fit: cover;
    }

    &__audio {
      height: max-content;
      width: 200px;
      border-radius: 10px;
    }

    &__file {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;

      .download-icon {
        padding: 5px !important;
        border-radius: 50% !important;
        background-color: darken($color: #00a1ce, $amount: 5%) !important;
      }
    }

    &__img_indicator {
      justify-content: end;
      padding: 1px;
      display: flex;
      background-color: #00a1ce;
      color: white;
      border-radius: 5px;
      margin-top: 2px;
      font-size: 10px;
    }
  }
}

.modal-icon {
  color: #ffffff;
  margin-right: 10px;
}

.sending-modal {
  &__video {
    height: max-content;
    width: 100%;
    border-radius: 10px;
  }

  &__img {
    height: auto;
    width: 100%;
  }
}

.messages-tree {
  .item__status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: green;
    margin-top: 12px;
    align-self: flex-start;
  }

  .item__popover {
    padding: 0 5px;

    & i {
      margin: 0px;
    }
  }

  height: 65vh;
  overflow-y: scroll;
  padding: 10px;
  width: 25%;
  border-right: 1px solid rgba(145, 158, 171, 0.2);

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(99, 115, 129, 0.48);
    border-radius: 10px;
  }


  .child {
    padding: 0px 10px !important;
    margin-left: 30px !important;
  }

  ul {
    line-height: 2em;
    padding-left: 1rem;
  }

  ul li > ul {
    padding-left: 2rem;

    li {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
  }

  ul li {
    position: relative;
    padding-left: 5px;
  }

  ul li::before {
    position: absolute;
    left: -10px;
    top: 0px;
    border-left: 2px solid grey;
    border-bottom: 2px solid grey;
    content: '';
    width: 10px;
    height: 1em;
  }

  ul li::after {
    position: absolute;
    left: -10px;
    bottom: 0px;
    border-left: 2px solid grey;

    content: '';
    width: 10px;
    height: 100%;
  }

  ul li:last-child::after {
    display: none;
  }
}
