.admin-course-detail-wrapper {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #DFE0EB;
	transition: all .2s linear;

	.btn-secondary {
		width: max-content;
	}

	label {
		padding-bottom: 8px;
	}

	.name-input { 
		width: max-content;
	 }


	 textarea {
		resize: none;
	 }
	 .ant-btn {
		width: 500px;
		text-align: start;

		@media (max-width: 1000px) {
			width: 100%;
		}
	}
	
}
