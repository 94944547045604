.user-page {
  .public-page-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px;
    border-radius: none;
    border: none;
    transition: all 0.2s linear;
    justify-content: center;
    align-items: center;
    height: auto;
    .client-details {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      gap: 30px;
      label {
        margin-bottom: 5px;
      }
    }

    .client-image {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      object-fit: cover;
    }
    .user-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    .file-input {
      width: 0px;
      opacity: 0;
    }
    .file-input-label {
      cursor: pointer;
      border: 1px solid;
      padding: 5px 8px;
      border-radius: 6px;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
    }
  }
}
