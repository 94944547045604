
.login-form {
  min-width: 30vw;
  background-color: #fff;
  padding: 25px;
  color: #000;
  border-radius: 2px;

  @media (max-width: 1100px) {
    min-width: 70vw;
  }
}

.login-title {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.form-label {
  color: rgb(176, 173, 173);
}

.login-line {
  display: block;
  width: 80%;
  height: 1px;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #B0B0B0;
}

.login-button {
  background-color: #019DCD;
  color: #fff;
  border: none;
  width: 100%;
  font-size: 25px;
  padding: 8px 0;
  border-radius: 50px;

  &:hover {
    background-color: #047ca0;
    color: #fff;
  }
}

.login-check-container {
  text-align: center;
  padding-bottom: 5px;
  input {
    padding-left: 10px;
    &:hover{
      cursor: pointer;
    }
  }
  .login-check-container-text{
    font-size: 18px;
    font-weight: 500;
  }
}

.login-telegram-link{
  text-align: center;
  margin-top: 10px;
  a {
    font-weight: 500;
  }
}
.registration-text {
  font-size: 15px;
  color: rgb(218, 7, 7);
}