.main-wrapper {
	.main-cards {
		display: grid;
		grid-template-columns: repeat(4,1fr);
		align-items: center;
		gap: 40px;

		@media (max-width: 1200px) {
			grid-template-columns: repeat(2,1fr);
		}

		@media (max-width: 800px) {
			grid-template-columns: 1fr;
		}
	}

	.trend-wrap {
		display: flex;
		gap: 20px;
		width: 100%;
		background-color: #fff;
		padding: 15px;
		border-radius: 8px;
		border: 3px solid #DFE0EB;

		.trend {
			width: 75%;
			height: 500px;
		}

		.trend-results {
			width: 25%;
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		@media (max-width: 1200px) {
			flex-direction: column;


				.trend {
					width: 100%;
				}

				.trend-results {
					width: 100%;
				}
		}
	}

	.tasks {
		display: flex;
		gap: 25px;
		margin-top: 20px;

		@media (max-width: 1000px) {
			flex-direction: column;
		}
	}
}