.catalog-wrapper {
  --header-bg-color: linear-gradient(
    0deg,
    rgba(32, 34, 48, 0.9473039215686274) 0%,
    rgba(9, 9, 121, 0.7176120448179272) 100%
  );
  width: 100%;
  .input-wrap {
    padding: 50px 20px;
    background-color: #515151;
    background-image: var(--header-bg-color), url('../../assets/pexels.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .ant-input-group-wrapper {
      .ant-input-wrapper {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .catalog-items-wrapper {
    display: flex;
    position: relative;
    gap: 10px;
    padding: 15px;
    height: 100%;
    background-color: #20232e;

    .tabs {
      @media (max-width: 1100px) {
        margin-left: 0 !important;
      }
    }

    .filter {
      width: max-content;
      z-index: 100;
      left: 1%;
      transition: all 0.3s linear;

      @media (max-width: 1100px) {
        left: 2.4%;
      }
    }

    .all-items-wrapper {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: 1fr;

      @media (max-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .ant-tabs-tab {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }

    .ant-tabs-nav-wrap {
      &::before {
        display: none;
      }
    }

    // .ant-tabs-tab-active {
    //   background-color: #008abd;
    //   padding: 5px;
    //   border-radius: 6px;
    //   text-decoration: none;

    //   .ant-tabs-tab-btn {
    //     color: #fff;
    //     text-decoration: none;
    //   }
    // }
    .ant-tabs-nav {
      .ant-tabs-tab {
        border: none;
        border-radius: 5px;
        line-height: 0;
        min-width: 100px;
        text-align: center;
        padding: 16px;
        background-color: #303240;
        margin-right: 10px;
        .ant-tabs-tab-btn {
          width: 100%;
        }
      }
      .ant-tabs-tab-active {
        background-color: rgb(0, 158, 204);
        border: none;
      }
    }

    @media (max-width: 1100px) {
      display: block;
    }
  }
}
