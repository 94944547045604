@import 'react-toastify/dist/ReactToastify.css';

@import 'src/components/header-component/headerComponent';
@import 'src/components/client-login-component/login.scss';
@import './components/catalogItem/catalogItem.scss';
@import './components/courseCard/courseCard.scss';
@import './components/filter/filter.scss';
@import './components/footer/footer.scss';
@import 'src/components/heroComponent/hero-component';
@import 'src/components/register/RegistrationComponent';
@import './components/searchBar/searchBar.scss';
@import 'src/components/student-active-course-component/student-active-course-component';

@import 'src/pages/admin-login/adminLogin';
@import './pages/catalog/catalog.scss';
@import 'src/pages/clint-login-page/ClientLogInPage';
@import 'src/pages/login-verification-page/LoginVerificationPage';
@import './pages/my-courses/my-course.scss';
@import 'src/components/client-course-navigation-tree-component/client-course-navigation-tree-component';
@import 'src/pages/client-course-page/client-course-page';
@import 'src/pages/register-verification-page/RegistrationVerificationPage';
@import 'src/pages/register/RegistrationPage';
@import './pages/payment/payment.scss';
@import './pages/payment/summary/summary.scss';
@import 'src/pages/dashboard/pages/mentor-pages/mentor-simple-message-chat/MentorSimpleMessageChat';
@import 'src/pages/dashboard/pages/mentor-pages/support-simple-chat/support-simple-chat';
@import './pages/dashboard/pages/mentor-pages/client-course/client.scss';
@import './pages/dashboard/pages/mentor-pages/course/course.scss';
@import './pages/dashboard/pages/admin-pages/videos/videos.scss';
@import './pages/dashboard/pages/admin-pages/profile/profile.scss';
@import 'src/pages/dashboard/pages/admin-pages/client/AdminClient';
@import './pages/dashboard/components/add-task/add-task.scss';
@import './pages/course-detail/courseDetail.scss';
@import './pages/course-detail/components/table-of-content/tableOfContent.scss';
@import './pages/my-courses/tabs/myCourses/myCourses.scss';
@import './pages/my-courses/tabs/finished-courses/finished-courses.scss';
@import './pages/catalog/catalog.scss';
@import './pages/dashboard/pages/mentor-pages/public-client-dashboard-page/public-page.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #21232e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
