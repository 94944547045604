.user-profile__main {
  padding: 50px 10px;
  .ant-tabs .ant-tabs-content-holder {
    border: 1px solid rgba(5, 5, 5, 0.06);
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
    &::before {
      border-bottom: none;
    }
  }
}

.update-phone {
  max-width: 40%;
  min-width: 300px;
  background-color: #fff;
  padding: 25px;
  color: #000;
  margin: 0 auto;
  .button {
    background-color: #019dcd;
    color: #fff;
    border: none;
    font-size: 18px;
    padding: 10px 40px;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    &:hover {
      background-color: #047ca0;
      color: #fff;
    }
  }
}
.update-phone__verif {
  .verification-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .button {
    font-size: 16px;
  }
  .btn-reset {
    font-size: 16px;
    padding: 10px 40px;
    border-radius: 50px;
  }
}
