	.teacher-about {
		border-radius: 0 0 8px 8px;
		padding: 20px;


		.teacher-image {
			padding: 5px 15px;
			display: flex;
			align-items: center;
			gap: 20px;

		}
	}
