.add-task-wrap {
width: 100%;
border-radius: 8px;
border: 3px solid #DFE0EB;
background-color: #fff;
	.add-task-top {
		display: flex;
		align-items: flex-start;
		gap: 20px;
		padding: 20px;
		justify-content: space-between;
		.card-title {
			display: flex;
			flex-direction: column;

			h6 {
				margin: 0;
				padding: 0;
				font-weight: bold;
			}
		}

		.view-detail {
			color: #3751FF;
			margin: 0;
			cursor: pointer;
			padding: 0;
		}
	}

	.tasks {
		display: flex;
		flex-direction: column;
		margin-top: 5px;

		.task {
			padding: 10px 20px;
			display: flex;
			justify-content: space-between;


			.btn-warning {
				font-weight: bold;
			}

		}

		.task-input {
			input {
				border: none;
				background-color: transparent;
				outline: none;
				width: 100%;
			}

			i {
				cursor: pointer;
				color: #9FA2B4;
				padding: 5px;
				background-color: #F0F1F7;
				border-radius: 8px;
				font-size: 20px;
			}
		}
	}

	.ant-checkbox-inner {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		margin: 0;
		padding: 0;
		&::after {
			width: 10px;
			height: 18px;
		}
	}
}


