.verification-wrapper {
  height: 92.88vh;
  display: grid;
  place-items: center;
  color: #000;
  background-color: #20232e;

  .details-wrap {
    padding: 40px;
    border: 10px solid #eae7e7;
    margin: 0 auto;

    .verification-input {
      color: #000;

      .ReactInputVerificationCode__item {
        background-color: #e8f0fe;
      }
    }
    .confirm-btn {
      background-color: #039dcd;
      color: #fff;
      width: 100%;
      border: none;
      padding: 20px;
      font-size: 22px;
      border-radius: 50px;
      transition: all 0.2s linear;

      &:active {
        transform: scale(0.98);
      }
    }
  }

  @media (max-width: 1100px) {
    height: 91.8vh;

    .details-wrap {
      padding: 15px;

      .verification-input {
        color: #000;

        .ReactInputVerificationCode__item {
          background-color: #e8f0fe;
          margin: 10px;
          width: 50px;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .verification-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    display: flex !important;
  }
  .verification-input {
    .ReactInputVerificationCode__container {
      width: fit-content;
      margin: 0 auto;
      gap: 5px;
      .ReactInputVerificationCode__item {
        margin: 0px !important;
        max-width: 50px !important;
        max-height: 60px !important;
      }
    }
    .confirm-btn {
      width: 80% !important;
      border: none !important;
      padding: 10px !important;
      font-size: 19px !important;
    }
    p {
      font-size: 15px !important;
    }
  }
}

@media (max-width: 358px) {
  .verification-wrapper .details-wrap {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
