.my-courses-items-wrapper {
	display: grid;
	grid-template-columns: repeat(3,1fr);
	gap: 20px;

	@media (max-width: 1200px) {
		grid-template-columns: repeat(2,1fr);
	}

	@media (max-width: 880px) {
		grid-template-columns: 1fr;
	}
}