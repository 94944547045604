.video-filter-wrapper {
  //scrollbar style
  .video-filter__list {
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 83px;

    &::-webkit-scrollbar-track {
      background-color: #31323f;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #31323f;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(38, 126, 163);
      border-radius: 10px;
    }
  }

  width: 300px;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  padding-top: 55px;
  z-index: 1;
  transition: all 0.2s linear;
  // overflow-y: scroll;
  // overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }

  .range-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 5px 10px;
    background-color: dodgerblue;
    color: #fff;
    .inner-range-wrap {
      text-align: center;
      flex: 1;
    }
    p {
      margin: 0;
      padding-bottom: 5px;
    }
    .range {
      width: 100%;
      height: 12px;
      border-radius: 10px;
      position: relative;
      background-color: #ddd;
    }
    .position-range {
      position: absolute;
      background-color: #31323f;
      height: 12px;
      border-radius: 10px;
    }
  }
  .ant-collapse-expand-icon {
    margin-top: 5px;
  }
  .anticon.anticon-right.ant-collapse-arrow {
    width: 15px;
    height: 15px;
  }
  .filter-collapse {
    min-height: 100%;
    user-select: none;

    .fa-bookmark {
      cursor: pointer;
      height: max-content;
    }

    .ant-collapse-header {
      background-color: #31323f;
      color: #fff;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-collapse-expand-icon {
        span {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .ant-collapse-content-box {
      background-color: #31323f;
      color: #fff;
      padding: 0;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px 20px;
      cursor: pointer;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
      // border-top-right-radius: 5px;
      // border-bottom-right-radius: 5px;
      &__selected {
        border-left: 3px solid #fff;
        background-color: #1e1e26;
      }
    }

    @media (max-width: 1100px) {
      height: 100%;
    }
  }
  .caret {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100%;
    background-color: #31323f;
    color: #fff;
    outline: none;
    border: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 991px) {
    width: 100% !important;
    padding-top: 119px;
    position: relative;
    .caret {
      display: none;
    }
    .video-filter__list {
      padding-bottom: 0;
    }
  }
  @media (max-width: 578px) {
    position: relative;
    top: 15px;
    min-height: 100%;
    padding-top: 55px;
    .video-filter__list {
      padding-bottom: 0;
    }
  }

  .Toastify__toast-container.Toastify__toast-container--top-right {
    top: 5em;
  }
}

.Toastify {
  z-index: 1000 !important;
}
.contents-menu {
  color: #fff;

  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.m_course-title {
  display: none;
}
@media (max-width: 576px) {
  .contents-menu {
    display: flex;
  }
  .range-wrap,
  .video-filter__list {
    display: none !important;
  }
  .range-wrap.contents-menu_active {
    display: flex !important;
  }
  .video-filter__list.contents-menu_active {
    display: block !important;
  }
  .video-player-wrapper__open {
    margin-top: 33px !important;
  }
  .m_course-title_active {
    display: block;
  }
}
