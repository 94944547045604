.about-us {
  box-sizing: border-box;
  background-color: #21232e;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .card-faq-title {
    color: black;
    margin-bottom: 1rem;
  }

  .fixed-img {
    height: 200px; /* Set a fixed height */
    object-fit: cover; /* Ensures images fill the area while maintaining aspect ratio */
    width: 100%; /* Ensures full width */
  }

  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limit to 5 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.faq-item-container{
  width: 80%;
  margin: auto;
  .faq-item-card {
    background-color: white;
    padding: 10px;

    .faq-item-title {
      text-align: center;
      margin: 3rem 0 5rem 0;
    }
    .faq-item-img{
      margin-bottom: 3rem;
    }
  }
}


