.task-card-wrap {
width: 100%;
border-radius: 8px;
border: 3px solid #DFE0EB;
background-color: #fff;
	.task-card-top {
		padding: 20px;
		display: flex;
		align-items: flex-start;
		gap: 20px;
		justify-content: space-between;
		.card-title {
			display: flex;
			flex-direction: column;

			h6 {
				margin: 0;
				padding: 0;
				font-weight: bold;
			}
		}

		.view-detail {
			color: #3751FF;
			margin: 0;
			cursor: pointer;
			padding: 0;
		}
	}

	.task-names {
		display: flex;
		flex-direction: column;
		margin-top: 10px;

		.task {
			padding: 20px 20px;
			display: flex;
			justify-content: space-between;
		}
	}
}