.timeline {
  box-sizing: border-box;
  background-color: #21232e;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .main {
    flex: 1;
    padding: 50px 10px;
  }
  h5 {
    color: #fff;
    margin-bottom: 50px;
  }
  .timeline-wrapper {
    margin: 0 auto;
    padding: 0 20px 0 50px;
  }
  .ant-timeline-item-tail {
    background-color: #fff;
  }
  .ant-timeline-item-head.ant-timeline-item-head-blue {
    color: #fff;
    border-color: orange;
    width: 15px;
    height: 15px;
    margin-inline-start: -6px;
  }
  .timeline-item {
    position: relative;
    width: 70%;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;

    &::before {
      content: "";
      position: absolute;
      left: 100%;
      top: 3%;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 7px solid #fff;
    }

    &__right {
      &::before {
        content: "";
        right: 100%;
        left: inherit;
        border-left: 0;
        top: 3%;
        border-right: 7px solid #fff;
      }
    }

    &__left {
      text-align: left;
      margin-right: 9px;
    }
  }
  .timeline-list {
    li {
      list-style: disc;
      a {
        color: #303240;
        &:hover {
          color: #1677ff;
        }
      }
    }
  }
  .ant-timeline-item.ant-timeline-item-right {
    .ant-timeline-item-content {
      display: flex;
      justify-content: flex-end;
    }
  }
  @media (max-width: 1200px) {
    .timeline-item {
      width: 80%;
    }
  }
  @media (max-width: 992px) {
    .timeline-item {
      width: 85%;
    }
  }
  @media (max-width: 768px) {
    .timeline-item {
      width: 85%;
      &__left {
        &::before {
          content: "";
          right: 100%;
          left: inherit;
          border-left: 0;
          top: 3%;
          border-right: 7px solid #fff;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .timeline-wrapper {
      margin: 0 auto;
      padding: 0 10px 0 10px;
    }
    .timeline-item {
      width: 95%;
    }
  }
}
